@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@layer base {
  body {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
}
@layer components {
  .hero-button {
    @apply rounded-full font-semibold text-sm md:text-[12.76px] text-white p-2 md:px-3 md:py-4;
  }
  .about-paragraphs {
    @apply font-normal text-center md:text-justify text-sm md:text-2xl;
  }
}
